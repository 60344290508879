<template>
  <div class="create-content-main">
    <div class="create-form">
      <h2 class="cpx-4 pt-4">編集</h2>
      <form class="p-3" id="create-content" autocomplete="off">
        <!-- <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold cp-label cpx-4">NO</label>
          <div class="cp-4 w-100">
            <b-form-input v-model="NO" disabled required></b-form-input>
          </div>
        </div> -->
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold cp-label cpx-4"
            >カテゴリ名<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="category_name"
              placeholder=""
            />
          </div>
        </div>
        <!-- <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold cp-label cpx-4"
            >カテゴリID<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="category_ID"
              placeholder=""
            />
          </div>
        </div> -->
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold cp-label cpx-4"
            >コンテンツ一括設定</label
          >
          <div class="cp-4 w-100">
            <multiselect
              v-model="list_content"
              placeholder="コンテンツを選択"
              label="text"
              class="col-sm-12 px-0"
              track-by="value"
              :options="listContentOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              style="width: 100%"
              v-bind:class="{ 'error-input': errorContentIdRequired }"
            ></multiselect>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4 py-4">
        <b-button
          v-on:click.prevent="updateCategory()"
          :disabled="isLoading"
          class="mr-4 btn-color"
          variant="success"
          >登録・更新する</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "EditCategory",
  data() {
    return {
      no: "",
      category_name: "",
      isLoading: false,
      category_ID: "",
      listContentOptions: [],
      list_content: [],
      errorContentIdRequired: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      page: 2,
      NO: null,
    };
  },
  created() {
    this.NO = this.$route.query.no;
    const data = {
      id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    const requestContent = {
      data: {
        shop_id: this.shop_id,
      },
    };
    this.getAllContents(requestContent);
    this.getCategoryById(data);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters([
      "categoryById",
      "success",
      "message",
      "error",
      "listAllContents",
    ]),
  },
  watch: {
    categoryById() {
      this.no = this.categoryById.id;
      this.category_name = this.categoryById.category_name;
      // this.category_ID = this.categoryById.category_id;
      if (this.categoryById.contents !== undefined) {
        this.list_content = this.categoryById.contents.map((item) => ({
          value: item.id,
          text: item.title,
        }));
      }
    },
    success() {
      if (this.success) {
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    listAllContents() {
      this.listContentOptions = this.listAllContents.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
  },
  methods: {
    ...mapActions({ getCategoryById: "getCategoryById" }),
    ...mapActions({ updateCategoryByID: "updateCategoryByID" }),
    ...mapActions({ getAllContents: "getAllContents" }),
    async updateCategory() {
      this.isLoading = true;
      const shop_id = this.shop_id;
      // const regex = Constants.FULL_WIDTH.test(this.category_ID);
      let array_content_id = [];
      this.list_content.map((item) => {
        array_content_id.push(item.value);
      });
      const formCreateCategory = {
        id: this.$route.params.id,
        shop_id: shop_id,
        category_name: this.category_name,
        // category_id: this.category_ID,
        content_id: array_content_id,
      };
      const dataReturn = await this.$store.dispatch(
        "updateCategoryByID",
        formCreateCategory
      );
      if (dataReturn.success) {
        this.$toasted.success(dataReturn.message);
        this.$router.go(-1);
      } else {
        this.$toasted.error(dataReturn.message);
        this.isLoading = false;
      }
    },
    returnList() {
      // this.$router.push({ name: "List Category" });
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.cp-label {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
</style>
